import React, { useRef, useEffect, useState } from 'react';
import Image from 'next/image';
import MillionaireCard from '@/components/common/millionaire-card';
import { Merchandise } from '@/redux/merchandise/slice';
import arrowIcon from '@/public/assets/icons/arrow-home.svg';
import Slider, { Settings as CarouselSettings } from 'react-slick';
import { CarouselArrowProps, mobileBreakPoint } from '@/utils/constants';
import { cardSliderInfo } from '@/utils/constants';
import classNames from 'classnames';
import { useGetRemainingMerchandisesQuery } from '@/redux/homepage/api';
import './new-silk.scss';

export interface SilkCarouselProps {
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    merchandises: Merchandise[];
    genreName?: string;
    isFavorite: Record<string | number, boolean>;
    setFavorite: React.Dispatch<React.SetStateAction<Record<string | number, boolean>>>;
    infinite?: boolean;
    readonly totalMerchandises: number;
    isNumberShown?: boolean;
}

type SliderType = keyof typeof cardSliderInfo;

function CarouselArrow(props: CarouselArrowProps) {
    const { className, onClick, prev } = props;
    const isDisabled = className?.includes('slick-disabled');

    return (
        <div
            className={classNames(
                'carousel-container__handle',
                prev ? 'carousel-container__prev' : 'carousel-container__next',
                isDisabled && 'carousel-container__handle--disabled'
            )}
            onClick={onClick}
        >
            <Image src={arrowIcon} className="carousel-container__handle-arrow" alt="arrow icon" />
        </div>
    );
}

const SilkCarousel = ({
    merchandises: _firstPageMerchandises,
    genreName,
    isFavorite,
    setOpenModal,
    setFavorite,
    totalMerchandises,
    isNumberShown,
}: SilkCarouselProps) => {
    const sliderRef = useRef<Slider | null>(null);
    const lastItemRef = useRef<Element | null>(null);
    const [page, setPage] = useState(1);
    const [merchandises, setMerchandises] = useState<Merchandise[]>([]);
    const { data: _fetchedMerchandises } = useGetRemainingMerchandisesQuery(
        { genre: genreName || '', page },
        { skip: page === 1 }
    );

    function getSliderLength(mode: SliderType) {
        const { slideLimit, slideWidth, gap } = cardSliderInfo[mode];
        if (sliderRef.current?.innerSlider?.list) {
            const threshold = Math.floor(sliderRef.current?.innerSlider?.list?.clientWidth / (slideWidth + gap));
            return Math.min(threshold, merchandises.length);
        }
        return Math.min(slideLimit, merchandises.length);
    }

    function handleLastItem(threshold: number, slideContainer: HTMLDivElement) {
        const visibleItems = Array.from(slideContainer.querySelectorAll('.slick-active'));
        if (merchandises.length >= threshold && visibleItems.length > 0) {
            const lastItem = visibleItems[visibleItems.length - 1];
            if (lastItemRef.current) lastItemRef.current.classList.remove('last-visible-item');
            lastItemRef.current = lastItem;
            lastItemRef.current?.classList.add('last-visible-item');
        }
    }

    function handleVisibility(mode: SliderType) {
        const { slideWidth, gap } = cardSliderInfo[mode];
        if (sliderRef.current?.innerSlider?.list) {
            const threshold = Math.floor(sliderRef.current?.innerSlider?.list?.clientWidth / (slideWidth + gap));
            handleLastItem(threshold, sliderRef.current?.innerSlider?.list);
        }
    }

    function handlePagination(nextSlide: number, mode: SliderType) {
        if (nextSlide + getSliderLength(mode) >= totalMerchandises) return;
        if (nextSlide + getSliderLength(mode) >= merchandises.length) {
            setPage((prev) => prev + 1);
        }
    }

    const settings: CarouselSettings = {
        slidesToScroll: 1,
        slidesToShow: getSliderLength('PC'),
        initialSlide: 0,
        infinite: false,
        arrows: true,
        variableWidth: true,
        swipeToSlide: true,
        onReInit: () => handleVisibility('PC'),
        className: 'carousel-container__visibility',
        nextArrow: <CarouselArrow />,
        prevArrow: <CarouselArrow prev />,
        beforeChange: (_currentSlide, nextSlide) => handlePagination(nextSlide, 'PC'),
        useCSS: true,
        useTransform: false,
        responsive: [
            {
                breakpoint: mobileBreakPoint,
                settings: {
                    slidesToShow: getSliderLength('SP'),
                    onReInit: () => handleVisibility('SP'),
                    beforeChange: (_currentSlide, nextSlide) => handlePagination(nextSlide, 'SP'),
                },
            },
        ],
    };

    useEffect(() => {
        setMerchandises(_firstPageMerchandises);
    }, [_firstPageMerchandises]);

    useEffect(() => {
        if (_fetchedMerchandises?.length) setMerchandises((prev) => [...prev, ..._fetchedMerchandises]);
    }, [_fetchedMerchandises]);

    useEffect(() => {
        merchandises.forEach((item) => {
            setFavorite((prev) => ({ ...prev, [item.id || -1]: item.isFavourite || false }));
        });
    }, [merchandises, setFavorite]);

    return (
        <div className="carousel-container">
            <Slider {...settings} ref={sliderRef}>
                {merchandises?.map((merchandise, index) => (
                    <MillionaireCard
                        key={merchandise?.id}
                        merchandise={merchandise}
                        genreName={genreName ?? (merchandise.genres?.[0]?.name || '')}
                        isFavorite={isFavorite[merchandise.id || -1]}
                        setFavorite={setFavorite}
                        setOpenModal={setOpenModal}
                        isNumberShown={isNumberShown}
                        index={index}
                        isNew={merchandise.isNew}
                        isTopTen={merchandise.isPopular}
                    />
                ))}
            </Slider>
        </div>
    );
};

export default SilkCarousel;
