import { HeroData } from '@/components/homepage/homepageMain';
import { apiSlice } from '../api/apiSlice';
import { Genre } from '../genre/slice';
import { Merchandise } from '../merchandise/slice';
import { API_ROUTES } from '@/utils/routes/apiRoutes';
import { apiV2Slice } from '../api/apiV2Slice';

export interface GenreWithMerchandise extends Genre {
    merchandises: Merchandise[];
    readonly totalMerchandises: number;
    readonly totalPages: number;
}

export const homepageApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMerchandiseByGenres: builder.query<GenreWithMerchandise[], void>({
            query() {
                return {
                    url: API_ROUTES.merchandiseByGenre,
                };
            },
            transformResponse: (response) => {
                return response.result || [];
            },
        }),
        getTopCarouselData: builder.query<HeroData, void>({
            query: () => API_ROUTES.getCarousel,
            providesTags: ['Carousel'],
            transformResponse: (response) => {
                return response.result || {};
            },
        }),
        getRemainingMerchandises: builder.query<Merchandise[], { page: number; genre: Genre['name'] }>({
            query(query) {
                return {
                    url: API_ROUTES.getRemaingingMerchandises,
                    params: { ...query },
                };
            },
            transformResponse: (response) => {
                return response.result.merchandises || [];
            },
        }),
        getPopularMerchandises: builder.query<Merchandise[], void>({
            query() {
                return {
                    url: API_ROUTES.getPopularMerchandises,
                };
            },
            transformResponse: (response) => {
                return response.result || [];
            },
        }),
    }),
});

export const v2HomepageAPISlice = apiV2Slice.injectEndpoints({
    endpoints: (builder) => ({
        getMerchandiseByGenres: builder.query<GenreWithMerchandise[], void>({
            query() {
                return {
                    url: API_ROUTES.merchandiseByGenre,
                };
            },
            transformResponse: (response) => {
                return response.result || [];
            },
        }),
    }),
});

export const {
    useGetMerchandiseByGenresQuery,
    useGetTopCarouselDataQuery,
    useGetRemainingMerchandisesQuery,
    util: { getRunningQueriesThunk: getHomePageRunningQueriesThunk },
} = homepageApiSlice;
