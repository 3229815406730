import React, { useEffect, useState } from 'react';
import { GenreWithMerchandise } from '@/redux/homepage/api';
import SilkCarousel from '../silk-carousel';
import TopCarousel from '../top-carousel';
import AppHeader from '@/components/common/app-header';
import LoginModal from '@/components/modals/login-modal';
import { useRouter } from 'next/router';
import { appRoutes } from '@/utils/routes/routeConfiguration';
import { Merchandise } from '@/redux/merchandise/slice';
import { appendPopularCarouselOnRow } from '@/utils/constants';
import './homepageMain.scss';

export interface HeroItem {
    id?: number;
    thumbnail: {
        pc: string;
        sp: string;
    };
    url: string;
    logo_company: string;
    logo_product: string;
    title: string;
    description: string;
    price: string;
    priceVisibility?: number;
    genre?: string;
    isFavourite: boolean | null;
}

export interface HeroData {
    banner?: HeroItem;
    carousel?: HeroItem[];
}

export interface HomePageMainProps {
    heroData: HeroData;
    categoryData: GenreWithMerchandise[];
    popularData: Merchandise[];
}

const HomePage = ({ heroData, categoryData, popularData }: HomePageMainProps) => {
    const router = useRouter();
    const [isFavorite, setFavorite] = useState<Record<string | number, boolean>>({});
    const [openModal, setOpenModal] = useState(false);
    const contentDivRef = React.useRef<HTMLDivElement>(null);
    const popularCarouselRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        popularCarouselRef.current?.style.setProperty('display', 'none');
        const childCount = contentDivRef.current?.children.length;
        const positionToAppend = Math.max(0, Math.min(appendPopularCarouselOnRow - 1, childCount || 0));
        if (popularCarouselRef.current) {
            contentDivRef.current?.insertBefore(
                popularCarouselRef.current as HTMLDivElement,
                contentDivRef.current?.children[positionToAppend]
            );
        }
        popularCarouselRef.current?.style.setProperty('display', 'block');
    }, [contentDivRef]);

    return (
        <div className="homepage-container">
            <div className="homepage-container__top-section">
                <AppHeader homepage />
                {heroData.carousel && (
                    <TopCarousel
                        carouselData={heroData.carousel}
                        isFavorite={isFavorite}
                        setFavorite={setFavorite}
                        setOpenModal={setOpenModal}
                    />
                )}
            </div>
            <div ref={contentDivRef} className="homepage-container__content">
                {categoryData &&
                    categoryData?.map((items, index) => {
                        return (
                            items.merchandises &&
                            items.merchandises.length > 0 && (
                                <div className="homepage-container__segment" key={index}>
                                    <div className="homepage-container__genre-new-view">
                                        <p className="homepage-container__genre">{items?.name}</p>
                                    </div>
                                    <div className="homepage-container__carousel">
                                        <SilkCarousel
                                            merchandises={
                                                items.merchandises && items.merchandises.length > 0
                                                    ? items.merchandises
                                                    : []
                                            }
                                            genreName={items.name}
                                            isFavorite={isFavorite}
                                            setFavorite={setFavorite}
                                            setOpenModal={setOpenModal}
                                            totalMerchandises={items.totalMerchandises}
                                        />
                                    </div>
                                </div>
                            )
                        );
                    })}
            </div>
            {popularData.length > 0 && (
                <div ref={popularCarouselRef} className="homepage-container__segment">
                    <div className="homepage-container__genre-new-view">
                        <p className="homepage-container__genre">人気の商品・サービス</p>
                    </div>
                    <div className="homepage-container__carousel">
                        <SilkCarousel
                            merchandises={popularData && popularData.length > 0 ? popularData : []}
                            isFavorite={isFavorite}
                            setFavorite={setFavorite}
                            setOpenModal={setOpenModal}
                            totalMerchandises={popularData.length}
                            isNumberShown={true}
                        />
                    </div>
                </div>
            )}
            <LoginModal
                toggle={openModal}
                handleToggle={async () => {
                    const { merchandise: merchandiseID, next } = router.query;
                    if (merchandiseID && next) {
                        await router.push(appRoutes.home, undefined, { scroll: false });
                    }
                    setOpenModal(false);
                }}
                onSuccess={async () => {
                    const { merchandise: merchandiseID, next } = router.query;
                    if (merchandiseID && next) {
                        await router.push({
                            pathname: String(next),
                            query: {
                                merchandise: merchandiseID,
                            },
                        });
                    }
                    setOpenModal(false);
                }}
            />
        </div>
    );
};

export default HomePage;
