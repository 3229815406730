import HomePage, { HomePageMainProps } from '@/components/homepage/homepageMain';
import AppLayout from '@/layouts/app/app.layout';
import React, { ReactElement } from 'react';
import { NextPageWithLayout } from './_app';
import HomepageMeta from '@/components/meta-tags/HomepageMeta';
import { reduxWrapper } from '@/redux/store';
import { homepageApiSlice, v2HomepageAPISlice } from '@/redux/homepage/api';
import { pageRoutes } from '@/utils/routes/urls';

export interface HomeProps extends HomePageMainProps {}

const Home: NextPageWithLayout<HomeProps> = ({ heroData, categoryData, popularData }) => {
    return (
        <React.Fragment>
            <HomepageMeta />
            <HomePage heroData={heroData} categoryData={categoryData} popularData={popularData} />
        </React.Fragment>
    );
};

export const getServerSideProps = reduxWrapper.getServerSideProps<HomeProps>((store) => async () => {
    try {
        const genresWithMerchandies = await store
            .dispatch(v2HomepageAPISlice.endpoints.getMerchandiseByGenres.initiate())
            .unwrap();
        const carouselData = await store.dispatch(homepageApiSlice.endpoints.getTopCarouselData.initiate()).unwrap();
        const popularData = await store.dispatch(homepageApiSlice.endpoints.getPopularMerchandises.initiate()).unwrap();
        await Promise.allSettled(store.dispatch(homepageApiSlice.util.getRunningQueriesThunk()));
        return {
            props: {
                heroData: carouselData || {},
                categoryData: genresWithMerchandies,
                popularData,
            },
        };
    } catch (error: any) {
        console.error(error);
        return {
            redirect: {
                destination: pageRoutes.internalServerError,
                permanent: false,
            },
        };
    }
});

Home.getLayout = function getLayout(page: ReactElement) {
    return <AppLayout isHome={true}>{page}</AppLayout>;
};

export default Home;
