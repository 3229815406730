import Head from 'next/head';

export default function HomepageMeta() {
    return (
        <Head>
            <meta name="title" content="MILLIONAIRE TV" />
            <meta
                name="description"
                content="ベンチャーTVは日本初の動画型スタートアップメディアです。400件を越える有望ベンチャーのみのピッチ動画を掲載。気になる企業には直接アプローチも可能。ベンチャーTVは日本のスタートアップを応援します。"
            />
            <meta name="url" content={process.env.NEXT_PUBLIC_HOST} />

            <meta property="og:title" content="MILLIONAIRE TV" />
            <meta
                property="og:description"
                content="ベンチャーTVは日本初の動画型スタートアップメディアです。400件を越える有望ベンチャーのみのピッチ動画を掲載。気になる企業には直接アプローチも可能。ベンチャーTVは日本のスタートアップを応援します。"
            />
            <meta property="og:image" content="/assets/images/homepage/ogp.png" />

            <title>MILLIONAIRE TV</title>
        </Head>
    );
}
