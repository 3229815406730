import { useState } from 'react';
import { useRouter } from 'next/router';
import Slider, { Settings } from 'react-slick';
import Image from 'next/image';
import Link from 'next/link';
import VideoModal from '@/components/modals/video-modal';
import LoginModal from '@/components/modals/login-modal';
import { HeroItem } from '../homepageMain';
import { useAddFavoriteMutation, useRemoveFavoriteMutation } from '@/redux/user/api';
import { ErrorToastContainer } from '@/components/toasts';
import useCurrentUser from '@/hooks/useCurrentUser.hook';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import classNames from 'classnames';
import { pageRoutes } from '@/utils/routes/urls';
import { mobileBreakPoint } from '@/utils/constants';
import { MSG_DISPLAY } from '@/utils/msgDisplay';
import './top-carousel.scss';

export interface TopCarouselProps {
    carouselData: HeroItem[];
    isFavorite: Record<string | number, boolean>;
    setFavorite: React.Dispatch<React.SetStateAction<Record<string | number, boolean>>>;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TopCarousel({ carouselData, isFavorite, setFavorite, setOpenModal }: TopCarouselProps) {
    const router = useRouter();
    const [addToFavorite, { isLoading: addOngoing }] = useAddFavoriteMutation();
    const [removeFavorite, { isLoading: removeOngoing }] = useRemoveFavoriteMutation();
    const authToken = useSelector<RootState, string>((state) => state.auth.loggedInToken);
    const { isViewer, isLoggedIn } = useCurrentUser();
    const [videoModal, setVideoModal] = useState<boolean>(false);

    const [url, setUrl] = useState<string>('');
    const settings: Settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        centerMode: false,
        slidesToScroll: 1,
        centerPadding: '161px',
        slidesToShow: 1,
        className: 'top-carousel__carousel-center',
        dotsClass: 'top-carousel__carousel-dot',
        responsive: [
            {
                breakpoint: mobileBreakPoint,
                settings: {
                    centerPadding: '34px',
                },
            },
        ],
    };

    function handleVideoPlay(url: string) {
        if (url) {
            setUrl(url);
            setVideoModal(() => true);
        }
    }

    function handleFavorite(id: number) {
        if (isFavorite[id]) {
            removeFavorite(id)
                .unwrap()
                .then((removed_id) => {
                    setFavorite((prev) => ({ ...prev, [Number(removed_id)]: false }));
                })
                .catch((err) => ErrorToastContainer({ message: err.message || MSG_DISPLAY.sthWentWrong }));
        } else {
            addToFavorite(id)
                .unwrap()
                .then((inserted_id) => {
                    setFavorite((prev) => ({ ...prev, [Number(inserted_id)]: true }));
                })
                .catch((err) => ErrorToastContainer({ message: err.message || MSG_DISPLAY.sthWentWrong }));
        }
    }

    async function handleInquiryRedirect(id: number) {
        if (authToken) {
            router.push({ pathname: pageRoutes.contact, query: { merchandise: id } });
        } else {
            setOpenModal((prev) => !prev);
            await router.push(
                {
                    pathname: router.asPath,
                    query: { merchandise: id, next: pageRoutes.contact },
                },
                undefined,
                { scroll: false, shallow: true }
            );
        }
    }

    return (
        <div className="top-carousel">
            <Slider {...settings}>
                {carouselData?.map((item) => (
                    <div key={item.id} className="top-carousel__image-container">
                        <picture>
                            <source media={`(max-width: ${mobileBreakPoint}px)`} srcSet={item.thumbnail.sp} />
                            <img
                                className="top-carousel__image"
                                src={item.thumbnail.pc}
                                alt={`carousel item ${item.id}`}
                            />
                        </picture>
                        <div className="top-carousel__image__overlay-linear-color"></div>
                        <div className="top-carousel__overlay-text-content">
                            {item.genre && (
                                <div className="top-carousel__overlay-text-content__overseas">{item.genre}</div>
                            )}
                            <div className="top-carousel__overlay-text-content__logo">
                                {item.logo_product && (
                                    <>
                                        <div className="top-carousel__overlay-text-content__logo__product">
                                            <img
                                                className="top-carousel__overlay-text-content__logo__product-company top-carousel__overlay-text-content__logo__product-company--special"
                                                src={item.logo_product}
                                                alt="product logo"
                                            />
                                        </div>
                                        <div className="top-carousel__overlay-text-content__logo__vertical-line"></div>
                                    </>
                                )}
                                <div className="top-carousel__overlay-text-content__logo_company">
                                    <img
                                        className="top-carousel__overlay-text-content__logo__product-company"
                                        src={item.logo_company}
                                        alt="company logo"
                                    />
                                </div>
                            </div>
                            <div className="top-carousel__overlay-text-content__heading">{item.title}</div>
                            <div className="top-carousel__overlay-text-content__description">{item.description}</div>
                            <Link
                                href={{
                                    pathname: pageRoutes.merchandiseDetails,
                                    query: {
                                        id: item.id,
                                    },
                                }}
                                className="top-carousel__overlay-text-content__view-more"
                            >
                                <span>VIEW MORE</span>
                                <Image
                                    className="top-carousel__overlay-text-content__view-more-image"
                                    src="/assets/icons/arrow-golden.svg"
                                    alt="golden arrow icon"
                                    height={13}
                                    width={73.5}
                                />
                            </Link>
                            <div className="top-carousel__overlay-text-content__buttons-group">
                                <button
                                    className="top-carousel__button top-carousel__button--video"
                                    onClick={() => handleVideoPlay(item.url)}
                                >
                                    <Image
                                        src="/assets/icons/button-black-icon.svg"
                                        alt="reproduction icon"
                                        width={10}
                                        height={11}
                                    />
                                    <span>再生</span>
                                </button>
                                <button className="top-carousel__button top-carousel__button--price">
                                    PRICE：{item.price}
                                </button>
                                {(!isLoggedIn || isViewer) && (
                                    <>
                                        <button
                                            className="top-carousel__button top-carousel__button--inquiry"
                                            onClick={() => handleInquiryRedirect(item.id || -1)}
                                        >
                                            お問い合わせ
                                        </button>
                                        <button
                                            className={classNames(
                                                'top-carousel__button top-carousel__button--favorite',
                                                (addOngoing || removeOngoing) &&
                                                    'top-carousel__button top-carousel__button--favorite-loading'
                                            )}
                                            onClick={() =>
                                                authToken
                                                    ? handleFavorite(item.id || -1)
                                                    : setOpenModal((prev) => !prev)
                                            }
                                            disabled={addOngoing || removeOngoing}
                                        >
                                            {addOngoing || removeOngoing ? (
                                                ''
                                            ) : (
                                                <>
                                                    {!isViewer || !isFavorite[item.id || -1] ? (
                                                        <Image
                                                            src={'/assets/icons/fav_off.svg'}
                                                            alt="addFavorite"
                                                            width={10}
                                                            height={10}
                                                            loading="eager"
                                                            priority={true}
                                                        />
                                                    ) : (
                                                        <Image
                                                            src={'/assets/icons/fav_on.svg'}
                                                            alt="removeFavorite"
                                                            width={11.4}
                                                            height={9.8}
                                                            loading="eager"
                                                            priority={true}
                                                        />
                                                    )}
                                                    <span>{'お気に入り'}</span>
                                                </>
                                            )}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
            <VideoModal
                url={url}
                toggle={Boolean(videoModal)}
                handleToggle={(value: boolean) => {
                    setUrl(() => '');
                    setVideoModal(value);
                }}
            />
        </div>
    );
}
